(function ($) {
    $(document).ready(function () {
        var $windowWidth = $(window).width();
        var $activeItemTitle = $('#lds-products > .item-title.active');

        var product = {
            init: function (width, activeTitle) {
                if (width < 768) {
                    activeTitle.next().css('height', this.calcProductItemHeight(activeTitle));
                }
            },
            show: function (width, clickTitle) {
                if (width < 768) {
                    if (!clickTitle.hasClass('active')) {
                        this.hide(clickTitle);
                        clickTitle.addClass('active').next().addClass('active').css('height', this.calcProductItemHeight(clickTitle));
                        this.scrollTo(200);
                    }
                } else if (width >= 768) {
                    if (!clickTitle.hasClass('active')) {
                        this.hide(clickTitle);
                        clickTitle.addClass('active').next().addClass('active');
                        this.scrollTo(500);
                    }
                }
            },
            hide: function (clickTitle) {
                clickTitle.parent().find('.item.active').css('height', 0);
                clickTitle.parent().find('.item-title.active, .item.active').removeClass('active');
            },
            scrollTo: function (speed) {
                $('html, body').animate({
                    scrollTop: $('#produkty').offset().top - 30
                }, speed);
            },
            calcProductItemHeight: function (activeTitle) {
                var $elementsInsideProductItem = activeTitle.next().find('>*');
                var $productItemHeight = 0;

                $elementsInsideProductItem.each(function () {
                    $productItemHeight += $(this).outerHeight();
                });

                return $productItemHeight;
            }
        }

        product.init($windowWidth, $activeItemTitle);

        $('#lds-products > .item-title').on('click', function () {
            product.show($windowWidth, $(this));
        });

        $(window).on('resize', function () {
            $windowWidth = $(window).width();
        });
    });
}(jQuery));