angular.module('articleContentApp', [])
    .controller('ArticleContentCtrl', ['$scope', '$location', '$anchorScroll', function ($scope, $location, $anchorScroll) {
        $scope.template = [{
            url: 'templates/b-scan-8500-b-stacja-wykrywania-materialow-wybuchowych-i-narkotykow.tpl.html'
        }, {
            url: 'templates/g-scan-pro-4500-g-wykrywacz-narkotykow-i-materialow-wybuchowych.tpl.html'
        }, {
            url: 'templates/i-scan-lds-3500-i-wykrywacz-materialow-wybuchowych.tpl.html'
        }, {
            url: 'templates/regulamin-i-polityka-prywatnosci.tpl.html'
        }, {
            url: 'templates/technologia-lds.tpl.html'
        }, {
            url: 'templates/wykrywanie-zagrozen-z-laser-detect-systems.tpl.html'
        }, {
            url: 'templates/zastosowania-lds.tpl.html'
        }];

        $anchorScroll.yOffset = 110;
    }]);