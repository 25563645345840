(function ($) {
    $(document).ready(function () {
        var cookie = {
            set: function (cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = 'expires=' + d.toGMTString();
                document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
            },
            get: function (cname) {
                var name = cname + '=';
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return '';
            },
            check: function () {
                var cookieVal = this.get('cookie_notice');

                if (cookieVal !== 'disabled') {
                    this.notice();
                }
            },
            notice: function () {
                var link = 'regulamin-i-polityka-prywatnosci.html##polityka-prywatnosci';
                $('body').append('<div id="cookie-notice" class="cookie-notice"><p>Stosujemy pliki Cookie i pliki sesyjne przeglądarki w celach statystycznych i marketingowych. Korzystając z serwisu, wyrażasz zgodę na ich stosowanie. Pliki możesz usunąć i blokować w ustawieniach przeglądarki. Czytaj więcej o polityce plików <a href="' + link + '">cookies i prywatności</a>.</p><span class="icon">i</span><span class="close-cookie-notice"></span></div>');
            }
        };

        cookie.check();

        $('body').on('click', '.close-cookie-notice', function () {
            cookie.set('cookie_notice', 'disabled', 30);
            $('#cookie-notice').addClass('cookie-hidden');
        });
    });
}(jQuery));