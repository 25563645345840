// reCAPTCHA widget
var widget;
var gRecaptchaResponse,
    verifyCallback = function (response) {
        gRecaptchaResponse = response;
    };
var onloadCallback = function () {
    widget = grecaptcha.render('grecaptcha', {
        'sitekey': '6LfsvCcUAAAAACw-Uki6rjOqM10m9kV5pdXDtQe3',
        'callback': verifyCallback,
        'theme': 'dark'
    });
};

// Form
(function ($) {
    var form = {
        add: function () {
            var html = '',
                field = [{
                    id: 'name',
                    label: 'Imię i Nazwisko:',
                    tag: 'input',
                    type: 'text'
                }, {
                    id: 'email',
                    label: 'Adres e-mail:',
                    tag: 'input',
                    type: 'email'
                }, {
                    id: 'phone',
                    label: 'Telefon:',
                    tag: 'input',
                    type: 'tel'
                }, {
                    id: 'message',
                    label: 'Wiadomość:',
                    tag: 'textarea',
                    type: ''
                }];

            html += '<form class="contact-form">';

            for (var i = 0; i < field.length; i++) {
                var fieldId = field[i].id,
                    fieldLabel = field[i].label,
                    fieldType = (field[i].type !== '') ? 'type="' + field[i].type + '"' : '',
                    fieldTag = field[i].tag;

                html += '<div class="form-group">';
                html += '<' + fieldTag + ' id="' + fieldId + '" class="form-control" placeholder="' + fieldLabel + '" name="' + fieldId + '" required ' + fieldType + '>' + ((field[i].tag !== 'input') ? '</' + field[i].tag + '>' : '');
                html += '</div>';
            }

            html += '<div class="row">';
            html += '<div class="col-sm-8">';
            html += '<div id="grecaptcha" class="grecaptcha"></div>';
            html += '</div>';
            html += '<div class="col-sm-4">';
            html += '<p class="submit"><button type="submit" class="btn btn-primary btn-lg">Wyślij</button></p>';
            html += '</div>';
            html += '</div>';
            html += '<div id="notice" class="notice"></div>';
            html += '</form>';

            $('#contact-form').append(html);
        },
        status: function (msg, formObj) {
            if (msg === 'phpmailer-success') {
                $('#notice').html('<div class="alert alert-success alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>Twoja wiadomość została wysłana pomyślnie. Dziękujemy!</div>').hide().slideDown();
                // Form and reCaptcha reset
                grecaptcha.reset();
                formObj[0].reset();
                console.log(formObj);
            } else if (msg === 'phpmailer-error') {
                $('#notice').html('<div class="alert alert-danger alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>Twoja wiadomość nie została wysłana. Prosimy spróbować później.</div>').hide().slideDown();
            } else if (msg === 'grecaptcha-false') {
                $('#notice').html('<div class="alert alert-danger alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>Udowodnij, że nie jesteś robotem!</div>').hide().slideDown();
            }
        },
        validation: function (formObj) {
            this.xhr(formObj);
        },
        xhr: function (formObj) {
            $.ajax({
                    url: 'phpmailer/mail.php',
                    type: 'POST',
                    data: formObj.serialize()
                })
                .done(function (response) {
                    form.status(response, formObj);
                })
                .fail(function (response) {
                    form.status(response);
                });
        }
    }

    form.add();

    $('body').on('submit', '#contact-form > form', function (event) {
        event.preventDefault();
        form.validation($(this));
    });
}(jQuery));