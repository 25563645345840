(function ($) {
    $(document).ready(function () {
        function init() {
            $('table > tbody td[data-type="cf"]').each(function () {
                var $str = $(this).text();
                $(this).html(replaceFormula($str));
            });
        }

        function replaceFormula(str) {
            var pattern = new RegExp('[0-9]');
            var newStr = '';
            for (var i = 0; i < str.length; i++) {
                var mark = str.charAt(i);
                if (pattern.test(mark) === true) {
                    newStr += '<sub>' + mark + '</sub>';
                } else {
                    newStr += mark;
                }
            }
            return newStr;
        }

        setTimeout(function () {
            init();
        }, 500);
    });
}(jQuery));