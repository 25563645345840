(function ($) {
    $(document).ready(function () {

        function initShrink(w) {
            if (w >= 10) {
                $('.navbar').addClass('shrink');
            } else {
                $('.navbar').removeClass('shrink');
            }
        }

        initShrink($(window).scrollTop());

        $(window).on('scroll', function () {
            initShrink($(this).scrollTop());
        });
    });
}(jQuery));