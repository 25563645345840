(function ($) {
    $(window).on('load', function () {

        var apps = {
            windowWidth: function () {
                return $(window).width();
            },
            dimensions: {
                // Szerokość kwadratu
                containerWidth: 0,
                // Wysokość kwadratu
                containerHeight: 0,
                // Średnica małego okręgu
                circleDiameter: 0,
                // Początek środka układu współrzędnych (kwadrat)
                coordinateOrigin: {
                    x: 0,
                    y: 0
                },
                // Kąt pomiędzy prostymi przechodzącymi przez środek układu współrzędnych i środki małych okregów
                angle: 37,
                // Długość od środka układu współrzędnych (kwadrat) do środka małego okręgu
                circleRadius: 0
            },
            init: function (width) {
                this.figcaption();

                if (width > 991) {
                    this.reset();
                    this.getDimensions();
                } else {
                    this.reset();
                }

            },
            figcaption: function () {
                $('#recipient figcaption').each(function () {
                    $(this).css({
                        height: $(this).outerHeight(),
                        bottom: 0
                    });
                });
            },
            getDimensions: function () {
                this.dimensions.containerWidth = $('#recipient').width();
                this.dimensions.containerHeight = $('#recipient').width();
                this.dimensions.circleDiameter = $('#recipient > li').width();
                this.dimensions.coordinateOrigin.x = (this.dimensions.containerWidth / 2) - (this.dimensions.circleDiameter / 2);
                this.dimensions.coordinateOrigin.y = (this.dimensions.containerHeight / 2) - (this.dimensions.circleDiameter / 2);
                this.dimensions.circleRadius = (this.dimensions.containerWidth / 2) - (this.dimensions.circleDiameter / 2);

                this.calc();
            },
            calc: function () {
                // Układ współrzędnych
                $('#recipient').css({
                    width: this.dimensions.containerWidth,
                    height: this.dimensions.containerHeight
                });

                // Zamieszczenie małych okręgów w środku układu współrzędnych
                $('#recipient > li').css({
                    left: this.dimensions.coordinateOrigin.x,
                    top: this.dimensions.coordinateOrigin.y
                });

                // Zamieszczenie małych okręgów na obwodzie większego okręgu
                var angle = 0;
                for (var i = $('#recipient > li').length; i > 0; i--) {
                    $('#recipient > li:eq(' + (i - 1) + ')').css({
                        'top': '-=' + (Math.sin(angle) * apps.dimensions.circleRadius),
                        'left': '+=' + (Math.cos(angle) * apps.dimensions.circleRadius),
                    });
                    angle = angle + apps.dimensions.angle;
                }
            },
            reset: function () {
                $('#recipient').removeAttr('style');
            }
        }

        apps.init(apps.windowWidth());

        $(window).on('resize', function () {
            apps.init(apps.windowWidth());
        });

    });
}(jQuery));