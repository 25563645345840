(function ($) {
    $(window).on('load', function () {
        function autoHeight() {
            var height = 0;
            $('#lds-usage > li > div').css('height', 'auto');
            $('#lds-usage > li').each(function () {
                if (height < $(this).outerHeight()) {
                    height = $(this).outerHeight();
                }
            });
            $('#lds-usage > li > div').css('height', height);
        }

        autoHeight();

        $(window).on('resize', function () {
            autoHeight();
        });
    });
}(jQuery));