(function ($) {
    $(document).ready(function () {

        function animatedScroll(el, width) {
            var $section = el.attr('href');
            var offset;
            if (width < 768) {
                offset = 55;
            } else if (width >= 768) {
                offset = 110;
            }
            $('html, body').animate({
                scrollTop: $($section).offset().top - offset
            }, 500, function () {
                $(this).clearQueue();
            });
        }

        $('body').on('click', 'a.scroll', function (event) {
            event.preventDefault();
            var $windowWidth = $(window).width();
            var $el = $(this);
            animatedScroll($el, $windowWidth);
        });
    });
}(jQuery));